import HttpUtil from '@/utils/HttpUtil';

// 获取人员分页数据
export const getPersonPage = (params?: any) => {
  let url = process.env.VUE_APP_SERVER_CODE + 'id/person/page';
  return HttpUtil.post(url, params);
};
// 获取人员 不分页数据
export const getPersonlist = (params?: any) => {
  let url = process.env.VUE_APP_SERVER_CODE + 'id/person/list';
  return HttpUtil.post(url, params);
};
// 获取用户 不分页数据
export const getUserlist = (params?: any) => {
  let url = process.env.VUE_APP_SERVER_CODE + 'id/user/id/list';
  return HttpUtil.post(url, params);
};

// 获取人事分页数据
export const userPersonPage = (params?: any) => {
  let url = process.env.VUE_APP_SERVER_CODE + 'id/user/query/userPersonPage';
  return HttpUtil.post(url, params);
};

// 获取用户任职列表分页
export const getUserTakeOffices = (params?: any) => {
  let url = process.env.VUE_APP_SERVER_CODE + 'id/user/query/getUserTakeOffices';
  return HttpUtil.post(url, params);
};

// 获取一个用户所有的任职信息
export const getUserAllTakeOffices = (params?: any) => {
  let url = process.env.VUE_APP_SERVER_CODE + 'id/user/query/getUserAllTakeOffices';
  return HttpUtil.post(url, params);
};

// 离职删除操作
export const takeOfficeOp = (params?: any) => {
  let url = process.env.VUE_APP_SERVER_CODE + 'id/takeOffice/takeOfficeOp';
  return HttpUtil.post(url, params);
};

// 新增修改人员
export const addOrEditPerson = (params?: any) => {
  let url = process.env.VUE_APP_SERVER_CODE + 'id/person/addOrEdit';
  return HttpUtil.post(url, params);
};
// 修改党组织信息
export const editPartyInfo = (params?: any) => {
  let url = process.env.VUE_APP_SERVER_CODE + 'id/person/editPartyInfo';
  return HttpUtil.post(url, params);
};

// 删除人员
export const deletePerson = (params?: any) => {
  let url = process.env.VUE_APP_SERVER_CODE + 'id/person/delete';
  return HttpUtil.get(url, params);
};

// 人员类型列表
export const userTypes = (params?: any) => {
  let url = process.env.VUE_APP_SERVER_CODE + 'id/user/query/userTypes';
  return HttpUtil.get(url, params);
};

// 查询证件号是否存在/
export const idCardExists = (params?: any) => {
  let url = process.env.VUE_APP_SERVER_CODE + 'id/person/idCardExists';
  return HttpUtil.get(url, params);
};

// 获取详情
export const personDetails = (params?: any) => {
  let url = process.env.VUE_APP_SERVER_CODE + 'id/person/personDetails';
  return HttpUtil.get(url, params);
};

// 验证账号是否存在
export const accountExists = (params?: any) => {
  let url = process.env.VUE_APP_SERVER_CODE + 'id/user/query/account?account=' + params.account;
  return HttpUtil.post(url);
};
